import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import config from "./aws-exports";
import { Amplify } from "aws-amplify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Amplify.configure(config);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
