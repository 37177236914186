import { Fragment, useEffect, useState } from "react";
import arrowUp from "../assets/arrow-up.png";
import arrowDown from "../assets/arrow-down.png";
import "../styles/Treatments.css";
import { Footer } from "../components/Footer";
import treatmentsText from "./../text/treatments.json";

type Entry = {
  index: number;
  title: string;
  details: string[];
};

type Expand = {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
  7: boolean;
};
export const Treatments = () => {
  const [textExpand, setTextExpand] = useState<Expand>({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });

  const handleUpdateState = (_indexNo: number) => {
    setTextExpand({
      ...textExpand,
      [_indexNo]: !textExpand[_indexNo as keyof Expand],
    });
  };

  useEffect(() => {
    document.title = "Treatments - Footprints Podiatry";
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pageContainer">
      <div className="Treatments">
        <div id="treatmentsText">
          <h2>
            Home Visit Service - all appointments £46{" "}
            <span style={{ fontWeight: "400", fontStyle: "italic" }}>
              (cash or card accepted)
            </span>
          </h2>
          <h3>Treatments include:</h3>

          <div id="treatmentsTableMobile">
            {treatmentsText.map((entry: Entry, loopIndex: number) => {
              return (
                <div
                  key={`${entry.title}-${loopIndex}-mobile`}
                  onClick={() => handleUpdateState(entry.index)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h4 style={{ paddingLeft: "10px" }}>{entry.title}</h4>
                    <img
                      src={
                        textExpand[entry.index as keyof Expand] === false
                          ? arrowDown
                          : arrowUp
                      }
                      alt="expand-text"
                      style={{ paddingLeft: "10px", height: "20px" }}
                    ></img>
                  </div>

                  {textExpand[entry.index as keyof Expand] === true &&
                    entry.details.map((para: string) => {
                      return (
                        <>
                          <p
                            key={`${para}-${loopIndex}-mobile`}
                            style={{
                              paddingRight: "10px",
                              paddingLeft: "10px",
                            }}
                          >
                            {para}
                          </p>
                        </>
                      );
                    })}
                </div>
              );
            })}
          </div>

          <table id="treatmentsTable">
            <tbody>
              {treatmentsText.map((entry: Entry, loopIndex: number) => {
                return (
                  <Fragment key={entry.index}>
                    <tr onClick={() => handleUpdateState(entry.index)}>
                      <td style={{ paddingLeft: "10px", fontWeight: "600" }}>
                        {entry.title}
                      </td>
                      <td>
                        {entry.details.map((para: string, index: number) => {
                          return (
                            <>
                              <p>{para}</p>
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};
