/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createCustomers = /* GraphQL */ `mutation CreateCustomers(
  $input: CreateCustomersInput!
  $condition: ModelCustomersConditionInput
) {
  createCustomers(input: $input, condition: $condition) {
    id
    forename
    surname
    phoneNumber
    emailAddress
    address
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomersMutationVariables,
  APITypes.CreateCustomersMutation
>;
export const updateCustomers = /* GraphQL */ `mutation UpdateCustomers(
  $input: UpdateCustomersInput!
  $condition: ModelCustomersConditionInput
) {
  updateCustomers(input: $input, condition: $condition) {
    id
    forename
    surname
    phoneNumber
    emailAddress
    address
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomersMutationVariables,
  APITypes.UpdateCustomersMutation
>;
export const deleteCustomers = /* GraphQL */ `mutation DeleteCustomers(
  $input: DeleteCustomersInput!
  $condition: ModelCustomersConditionInput
) {
  deleteCustomers(input: $input, condition: $condition) {
    id
    forename
    surname
    phoneNumber
    emailAddress
    address
    message
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomersMutationVariables,
  APITypes.DeleteCustomersMutation
>;
export const createBlog = /* GraphQL */ `mutation CreateBlog(
  $input: CreateBlogInput!
  $condition: ModelBlogConditionInput
) {
  createBlog(input: $input, condition: $condition) {
    id
    date
    title
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBlogMutationVariables,
  APITypes.CreateBlogMutation
>;
export const updateBlog = /* GraphQL */ `mutation UpdateBlog(
  $input: UpdateBlogInput!
  $condition: ModelBlogConditionInput
) {
  updateBlog(input: $input, condition: $condition) {
    id
    date
    title
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBlogMutationVariables,
  APITypes.UpdateBlogMutation
>;
export const deleteBlog = /* GraphQL */ `mutation DeleteBlog(
  $input: DeleteBlogInput!
  $condition: ModelBlogConditionInput
) {
  deleteBlog(input: $input, condition: $condition) {
    id
    date
    title
    content
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBlogMutationVariables,
  APITypes.DeleteBlogMutation
>;
