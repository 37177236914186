import "../styles/Home.css";
import gradPic from "../assets/grad-pic.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Footer } from "../components/Footer";

export const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Home - Footprints Podiatry";
  });
  return (
    <div className="pageContainer">
      <div className="About">
        <div className="homeTop">
          <h2 className="centeredText">About me!</h2>
          <img
            alt="picture-from-my-graduation"
            src={gradPic}
            className="homeImg"
          ></img>
          <h3> Sophie Archibald BSc (Hons) - HCPC Registered Podiatrist</h3>

          <div className="homeText">
            <p>
              Hi, I’m Sophie! I'm a fully qualified and insured podiatrist and I
              provide a home visit service to patients in{" "}
              <span style={{ fontWeight: "600" }}>
                Midlothian and Tweeddale.
              </span>
            </p>
            <p>
              I graduated from Queen Margaret University in 2019 with an honours
              degree in podiatry. Since then, I have worked in both a clinic and
              domicillary setting but I really enjoy getting out to meet
              patients and having a chat!
            </p>
            <p>
              One of my primary passions is offering a calm and gentle
              experience. I understand that appointments can be anxiety-inducing
              or unfamiliar for some, and I take great joy in guiding people
              through the process, helping them leave feeling more at ease and
              lighter on their feet.
            </p>
            <p>
              Please have a look through the{" "}
              <button id="homeLink" onClick={() => navigate("/treatments")}>
                treatments I offer
              </button>{" "}
              and{" "}
              <button id="homeLink" onClick={() => navigate("/contact")}>
                get in touch
              </button>{" "}
              to book an appointment!
            </p>
          </div>
        </div>

        <div className="homeTextBottom">
          <h2>What is a Podiatrist?</h2>
          <p>
            A podiatrist (or chiropodist) is a health professional that
            specialises in the health of the lower limb- particularly feet! We
            can diagnose and treat a wide range of conditions whilst looking to
            prevent and spot any further disorders.
          </p>
          <p>
            To practice as a podiatrist in the UK, you must complete a degree in
            podiatry, which is approved by the Health and Care Professions
            Council (HCPC). We are required to regularly engage in CPD
            (Continuing Professional Development) to stay current with the
            latest developments, best practices and safety protocol in the
            podiatry field.
          </p>
          <img
            src={gradPic}
            alt="picture-from-my-graduation"
            className="bottomImg"
          ></img>
        </div>
      </div>
      <Footer />
    </div>
  );
};
