import { useEffect, useState } from "react";
import fb from "../assets/fb.png";
import email from "../assets/email.svg";
import phone from "../assets/phone.png";
import "../styles/Contact.css";
import { Footer } from "../components/Footer";
import { generateClient } from "aws-amplify/api";
import { createCustomers } from "../graphql/mutations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Contact = () => {
  const notify = () => {
    setFormState({
      forename: "",
      surname: "",
      address: "",
      emailAddress: "",
      phoneNumber: "",
      message: "",
    });
    toast("Sent!");
  };
  interface EmailParams {
    forename: string | undefined;
    surname: string | undefined;
    address: string | undefined;
    emailAddress: string | undefined;
    phoneNumber: string | undefined;
    message: string | undefined;
  }
  useEffect(() => {
    document.title = "Contact - Footprints Podiatry";
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formState, setFormState] = useState<EmailParams>({
    forename: undefined,
    surname: undefined,
    address: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
    message: undefined,
  });

  const [errorState, setErrorState] = useState<string | undefined>(undefined);
  const client = generateClient();
  const handleEmailSubmit = async (e: any) => {
    e.preventDefault();
    const { forename, surname, address, emailAddress, phoneNumber, message } =
      formState;

    if (
      forename &&
      surname &&
      address &&
      emailAddress &&
      phoneNumber &&
      message
    ) {
      if (isNaN(+phoneNumber) === true) {
        setErrorState("Phone number must be a number");
      } else {
        try {
          await client.graphql({
            query: createCustomers,
            variables: {
              input: {
                forename,
                surname,
                address,
                emailAddress,
                phoneNumber,
                message,
              },
            },
          });
          console.log("sent");
          notify();
          setErrorState(undefined);
        } catch (e: any) {
          setErrorState(e.errors[0].message);
          console.log(e);
        }
      }
    } else {
      setErrorState(
        "You must enter a name, valid phone number, email, address and message"
      );
    }
  };
  return (
    <div className="pageContainer">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <h1 id="availabilityNotice">
        Currently for patients across Midlothian and Tweeddale
      </h1>
      <p
        style={{ textAlign: "center", marginRight: "40px", marginLeft: "40px" }}
      >
        For all enquires, please provide your full name, address and contact
        details
      </p>
      <div className="Contact">
        <form onSubmit={(e) => handleEmailSubmit(e)} id="contactForm">
          {errorState && <h5>{errorState}</h5>}
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0",
              color: "black",
            }}
          >
            Send a message!
          </h3>
          <label>Name *</label>
          <div
            style={{ display: "flex", alignItems: "center", color: "black" }}
          >
            <input
              style={{ width: "100%", marginRight: "10px" }}
              placeholder="Forename"
              type="text"
              onChange={(e) => {
                setFormState({ ...formState, forename: e?.target.value });
                setErrorState(undefined);
              }}
              value={formState.forename}
            />
            <input
              style={{ width: "100%" }}
              placeholder="Surname"
              type="text"
              onChange={(e) => {
                setFormState({ ...formState, surname: e?.target.value });
                setErrorState(undefined);
              }}
              value={formState.surname}
            />
          </div>
          <label>Phone number *</label>
          <input
            placeholder="Your phone number"
            type="text"
            onChange={(e) => {
              setFormState({
                ...formState,
                phoneNumber: e?.target.value,
              });
              setErrorState(undefined);
            }}
            value={formState.phoneNumber}
          />
          <label>Email *</label>
          <input
            placeholder="Your email"
            type="text"
            onChange={(e) => {
              setFormState({ ...formState, emailAddress: e?.target.value });
              setErrorState(undefined);
            }}
            value={formState.emailAddress}
          />
          <label>Address *</label>
          <input
            placeholder="Your address"
            type="text"
            onChange={(e) => {
              setFormState({ ...formState, address: e?.target.value });
              setErrorState(undefined);
            }}
            value={formState.address}
          />
          <label>Message *</label>
          <textarea
            style={{ fontFamily: "monospace", fontSize: "medium" }}
            placeholder="Type your message here..."
            onChange={(e) => {
              setFormState({ ...formState, message: e?.target.value });
              setErrorState(undefined);
            }}
            value={formState.message}
          />
          <input type="submit" value="Send" id="submitButton" />
        </form>

        <div className="links">
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            Contact Details
          </h3>
          <a
            href="tel:07784494265"
            id="individual-links"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img
              id="phone"
              alt="phone"
              src={phone}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
                minWidth: "25px",
              }}
            ></img>
            <p>07784494265</p>
          </a>
          <a
            href="mailto:pod.footprints@gmail.com"
            id="individual-links"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img
              id="email"
              alt="email"
              src={email}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
                minWidth: "30px",
              }}
            ></img>
            <p>pod.footprints@gmail.com</p>
          </a>
          <a
            href={
              "https://www.facebook.com/profile.php?id=61567610917163&mibextid=LQQJ4d"
            }
            id="individual-links"
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <img
              id="fb"
              alt="facebook-logo"
              src={fb}
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
                height: "30px",
                minWidth: "30px",
              }}
            ></img>
            <p>Footprints Podiatry</p>
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};
